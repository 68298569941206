

const databits = [
  {
    
    title: 'Términos y condicones de uso',
    content:
      'Para comprar cualquier producto es necesario estar registrado y contar con una cuenta de usuario. El tiempo de entrega depende de la ciudad seleccionada como destino del producto. Una vez autorizada la compra, se emite la factura y se despacha la mercadería a la dirección de entrega señalada. El servicio de “Compras seguras por Internet” tiene como objetivo evitar posibles fraudes, para lo cual analiza en línea todas las órdenes de compra bajo una serie de algoritmos estadísticos de inteligencia artificial. El procedimiento puede dar como resultado la autorización del pedido o la necesidad de realizar un análisis más minucioso del mismo. Dependiendo del resultado del análisis, se autoriza la compra o se declara el pedido como fraude y queda cancelado. Cuando alguna transacción se declara fraudulenta, el sistema la bloquea de inmediato e impide que se concrete la compra.', 
   
  }
  

];
export default databits;
